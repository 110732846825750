<template>
  <div class="flix-weekdays">
    <h4>
      {{ $t("time.weekdays.title") }}
      <help>{{ $t("time.weekdays.info") }}</help>
    </h4>
    <transition-group class="flix-container" name="changeWeekday">
      <div
        href="#"
        class="flix-item"
        v-for="(checkActive, key) in dates"
        :key="updateKey + weekdays[key]"
        :class="{ active: checkActive, inactive: !checkActive }"
      >
        <div>
          <b>{{ weekdays[key] }}</b>
        </div>

        <a href="#" class="flix-toggle" @click.prevent="setActive(key)">
          <icon
            class="flix-icon flix-bg-success"
            :class="{ inactive: !checkActive }"
            id="check"
          />
          <icon
            class="flix-icon flix-bg-grey"
            :class="{ inactive: checkActive }"
            id="minus"
          />
        </a>

        <span class="flix-opening" v-if="checkActive">{{
          $t("time.weekdays.open")
        }}</span>
        <span class="flix-opening" v-else>{{
          $t("time.weekdays.closed")
        }}</span>

        <span class="flix-clock" v-if="checkActive">
          <div
            :class="{
              'flix-form-group flix-flex flix-flex-list flix-gap-10':
                $store.state.business.unsaved.type === 'services'
            }"
            style="align-items: flex-start; justify-content: flex-start"
          >
            <timePickerRange
              :range="5"
              :time="active[key]"
              :callback="function (ret) { changeTime(key, ret) }"
            />
            <weekdayServices
              v-if="$store.state.business.unsaved.type === 'services'"
              :index="key"
            />
          </div>
          <a
            v-if="getChanged(key)"
            @click.prevent="setChangeToAll(key)"
            href="#"
            style="margin-top: 10px; display: block"
            class="flix-html-a flix-text-success flix-html-strong"
            >{{ $t("time.weekdays.take") }} <icon id="arrow-thin-right"
          /></a>
        </span>
      </div>
    </transition-group>
    <div
      style="margin-top: 100px; margin-bottom: 50px"
      v-if="$store.state.business.unsaved.type === 'services'"
    >
      <pause />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    pause() {
      return import("@/components/business/time/pause");
    },
    weekdayServices() {
      return import("@/components/business/time/weekdayServices");
    },
    timePickerRange() {
      return import("@/components/default/form/timePickerRange");
    }
  },
  props: {},
  data() {
    return {
      changed: [],
      weekdays: this.$t("dates.weekdays"),
      active: JSON.parse(
        JSON.stringify(this.$store.state.business.unsaved.include.weekdays)
      ),
      updateKey: new Date().getTime(),
      dates: false
    };
  },
  mounted() {
    this.dates = this.getWeekdays();
  },
  methods: {
    setChangeToAll(key) {
      var val = this.active[key];
      var ret = {};
      Object.keys(this.active).forEach(function(k) {
        if (this.active[k] !== false) {
          ret[k] = val;
        }
      }.bind(this));
      this.active = ret;
      this.changed = [];
      this.setSave();
      this.updateKey = new Date().getTime();
    },
    getChanged(key) {
      if (Object.keys(this.active).length <= 1) {
        return false;
      }
      if (typeof this.changed[key] !== "undefined") {
        return true;
      }
    },
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.include.weekdays = {};
      var active = JSON.parse(JSON.stringify(this.active));
      data.include.weekdays = active;
      var tags = this.$store.getters["service/tags"];

      Object.keys(active).forEach(function(k) {
        if (active[k] === "null" || active[k] === null) {
          data.include.weekdays[k] = false;
          return true;
        }
        if (
          data.type === "services" &&
          !isNaN(parseInt(data.include.weekdays[k][2]))
        ) {
          data.include.weekdays[k][2] = Object.keys(tags)[0];
        }

        if (typeof data.max_applicants[k] === "undefined") {
          data.max_applicants[k] = 1;
        }
        if (typeof data.next_appointments[k] === "undefined") {
          data.next_appointments[k] = 60;
        }
      });

      this.$store.commit("business/prefetch", data);
    },
    changeTime(ret, time) {
      this.changed[ret] = true;
      this.active[ret] = time;
      this.setSave();
    },
    getWeekdays() {
      var r = {};
      var active = this.active;
      this.weekdays.forEach(function(weekday, key) {
        r[key] = false;
        if (typeof active[key] !== "undefined" && active[key]) {
          r[key] = true;
        }
      });
      return r;
    },
    setActive(index) {
      if (typeof this.active[index] === "object") {
        this.active[index] = false;
        this.dates[index] = false;
      } else {
        this.dates[index] = true;
        this.active[index] = ["09:00", "18:00", 60];
      }
      this.setSave();
    }
  }
};
</script>
<style lang="sass" scoped></style>
